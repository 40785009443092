import { USA_FIPS, USA_DISPLAY_NAME } from './ConstantsGeography'

export const STATE_FIPS_MAP: Record<string, string> = {
  [USA_FIPS]: USA_DISPLAY_NAME,
  /* cSpell:disable */
  '01': 'Alabama',
  '02': 'Alaska',
  '04': 'Arizona',
  '05': 'Arkansas',
  '06': 'California',
  '08': 'Colorado',
  '09': 'Connecticut',
  10: 'Delaware',
  11: 'District of Columbia',
  12: 'Florida',
  13: 'Georgia',
  15: 'Hawaii',
  16: 'Idaho',
  17: 'Illinois',
  18: 'Indiana',
  19: 'Iowa',
  20: 'Kansas',
  21: 'Kentucky',
  22: 'Louisiana',
  23: 'Maine',
  24: 'Maryland',
  25: 'Massachusetts',
  26: 'Michigan',
  27: 'Minnesota',
  28: 'Mississippi',
  29: 'Missouri',
  30: 'Montana',
  31: 'Nebraska',
  32: 'Nevada',
  33: 'New Hampshire',
  34: 'New Jersey',
  35: 'New Mexico',
  36: 'New York',
  37: 'North Carolina',
  38: 'North Dakota',
  39: 'Ohio',
  40: 'Oklahoma',
  41: 'Oregon',
  42: 'Pennsylvania',
  44: 'Rhode Island',
  45: 'South Carolina',
  46: 'South Dakota',
  47: 'Tennessee',
  48: 'Texas',
  49: 'Utah',
  50: 'Vermont',
  51: 'Virginia',
  53: 'Washington',
  54: 'West Virginia',
  55: 'Wisconsin',
  56: 'Wyoming',
  60: 'American Samoa',
  66: 'Guam',
  69: 'Northern Mariana Islands',
  72: 'Puerto Rico',
  78: 'U.S. Virgin Islands',
  /* cSpell:enable */
}

export type StateFipsCode = keyof typeof STATE_FIPS_MAP

export const COUNTY_FIPS_MAP: Record<string, string> = {
  /* cSpell:disable */
  '01001': 'Autauga',
  '01003': 'Baldwin',
  '01005': 'Barbour',
  '01007': 'Bibb',
  '01009': 'Blount',
  '01011': 'Bullock',
  '01013': 'Butler',
  '01015': 'Calhoun',
  '01017': 'Chambers',
  '01019': 'Cherokee',
  '01021': 'Chilton',
  '01023': 'Choctaw',
  '01025': 'Clarke',
  '01027': 'Clay',
  '01029': 'Cleburne',
  '01031': 'Coffee',
  '01033': 'Colbert',
  '01035': 'Conecuh',
  '01037': 'Coosa',
  '01039': 'Covington',
  '01041': 'Crenshaw',
  '01043': 'Cullman',
  '01045': 'Dale',
  '01047': 'Dallas',
  '01049': 'DeKalb',
  '01051': 'Elmore',
  '01053': 'Escambia',
  '01055': 'Etowah',
  '01057': 'Fayette',
  '01059': 'Franklin',
  '01061': 'Geneva',
  '01063': 'Greene',
  '01065': 'Hale',
  '01067': 'Henry',
  '01069': 'Houston',
  '01071': 'Jackson',
  '01073': 'Jefferson',
  '01075': 'Lamar',
  '01077': 'Lauderdale',
  '01079': 'Lawrence',
  '01081': 'Lee',
  '01083': 'Limestone',
  '01085': 'Lowndes',
  '01087': 'Macon',
  '01089': 'Madison',
  '01091': 'Marengo',
  '01093': 'Marion',
  '01095': 'Marshall',
  '01097': 'Mobile',
  '01099': 'Monroe',
  '01101': 'Montgomery',
  '01103': 'Morgan',
  '01105': 'Perry',
  '01107': 'Pickens',
  '01109': 'Pike',
  '01111': 'Randolph',
  '01113': 'Russell',
  '01115': 'St. Clair',
  '01117': 'Shelby',
  '01119': 'Sumter',
  '01121': 'Talladega',
  '01123': 'Tallapoosa',
  '01125': 'Tuscaloosa',
  '01127': 'Walker',
  '01129': 'Washington',
  '01131': 'Wilcox',
  '01133': 'Winston',
  '02013': 'Aleutians East Borough',
  '02016': 'Aleutians West Census Area',
  '02020': 'Anchorage',
  '02050': 'Bethel Census Area',
  '02060': 'Bristol Bay Borough',
  '02068': 'Denali Borough',
  '02070': 'Dillingham Census Area',
  '02090': 'Fairbanks North Star Borough',
  '02100': 'Haines Borough',
  '02105': 'Hoonah-Angoon Census Area',
  '02110': 'Juneau City and Borough',
  '02122': 'Kenai Peninsula Borough',
  '02130': 'Ketchikan Gateway Borough',
  '02150': 'Kodiak Island Borough',
  '02158': 'Kusilvak Census Area',
  '02164': 'Lake and Peninsula Borough',
  '02170': 'Matanuska-Susitna Borough',
  '02180': 'Nome Census Area',
  '02185': 'North Slope Borough',
  '02188': 'Northwest Arctic Borough',
  '02195': 'Petersburg Borough',
  '02198': 'Prince of Wales-Hyder Census Area',
  '02220': 'Sitka City and Borough',
  '02230': 'Skagway',
  '02240': 'Southeast Fairbanks Census Area',
  '02261': 'Valdez-Cordova Census Area',
  '02275': 'Wrangell City and Borough',
  '02282': 'Yakutat City and Borough',
  '02290': 'Yukon-Koyukuk Census Area',
  '04001': 'Apache',
  '04003': 'Cochise',
  '04005': 'Coconino',
  '04007': 'Gila',
  '04009': 'Graham',
  '04011': 'Greenlee',
  '04012': 'La Paz',
  '04013': 'Maricopa',
  '04015': 'Mohave',
  '04017': 'Navajo',
  '04019': 'Pima',
  '04021': 'Pinal',
  '04023': 'Santa Cruz',
  '04025': 'Yavapai',
  '04027': 'Yuma',
  '05001': 'Arkansas',
  '05003': 'Ashley',
  '05005': 'Baxter',
  '05007': 'Benton',
  '05009': 'Boone',
  '05011': 'Bradley',
  '05013': 'Calhoun',
  '05015': 'Carroll',
  '05017': 'Chicot',
  '05019': 'Clark',
  '05021': 'Clay',
  '05023': 'Cleburne',
  '05025': 'Cleveland',
  '05027': 'Columbia',
  '05029': 'Conway',
  '05031': 'Craighead',
  '05033': 'Crawford',
  '05035': 'Crittenden',
  '05037': 'Cross',
  '05039': 'Dallas',
  '05041': 'Desha',
  '05043': 'Drew',
  '05045': 'Faulkner',
  '05047': 'Franklin',
  '05049': 'Fulton',
  '05051': 'Garland',
  '05053': 'Grant',
  '05055': 'Greene',
  '05057': 'Hempstead',
  '05059': 'Hot Spring',
  '05061': 'Howard',
  '05063': 'Independence',
  '05065': 'Izard',
  '05067': 'Jackson',
  '05069': 'Jefferson',
  '05071': 'Johnson',
  '05073': 'Lafayette',
  '05075': 'Lawrence',
  '05077': 'Lee',
  '05079': 'Lincoln',
  '05081': 'Little River',
  '05083': 'Logan',
  '05085': 'Lonoke',
  '05087': 'Madison',
  '05089': 'Marion',
  '05091': 'Miller',
  '05093': 'Mississippi',
  '05095': 'Monroe',
  '05097': 'Montgomery',
  '05099': 'Nevada',
  '05101': 'Newton',
  '05103': 'Ouachita',
  '05105': 'Perry',
  '05107': 'Phillips',
  '05109': 'Pike',
  '05111': 'Poinsett',
  '05113': 'Polk',
  '05115': 'Pope',
  '05117': 'Prairie',
  '05119': 'Pulaski',
  '05121': 'Randolph',
  '05123': 'St. Francis',
  '05125': 'Saline',
  '05127': 'Scott',
  '05129': 'Searcy',
  '05131': 'Sebastian',
  '05133': 'Sevier',
  '05135': 'Sharp',
  '05137': 'Stone',
  '05139': 'Union',
  '05141': 'Van Buren',
  '05143': 'Washington',
  '05145': 'White',
  '05147': 'Woodruff',
  '05149': 'Yell',
  '06001': 'Alameda',
  '06003': 'Alpine',
  '06005': 'Amador',
  '06007': 'Butte',
  '06009': 'Calaveras',
  '06011': 'Colusa',
  '06013': 'Contra Costa',
  '06015': 'Del Norte',
  '06017': 'El Dorado',
  '06019': 'Fresno',
  '06021': 'Glenn',
  '06023': 'Humboldt',
  '06025': 'Imperial',
  '06027': 'Inyo',
  '06029': 'Kern',
  '06031': 'Kings',
  '06033': 'Lake',
  '06035': 'Lassen',
  '06037': 'Los Angeles',
  '06039': 'Madera',
  '06041': 'Marin',
  '06043': 'Mariposa',
  '06045': 'Mendocino',
  '06047': 'Merced',
  '06049': 'Modoc',
  '06051': 'Mono',
  '06053': 'Monterey',
  '06055': 'Napa',
  '06057': 'Nevada',
  '06059': 'Orange',
  '06061': 'Placer',
  '06063': 'Plumas',
  '06065': 'Riverside',
  '06067': 'Sacramento',
  '06069': 'San Benito',
  '06071': 'San Bernardino',
  '06073': 'San Diego',
  '06075': 'San Francisco',
  '06077': 'San Joaquin',
  '06079': 'San Luis Obispo',
  '06081': 'San Mateo',
  '06083': 'Santa Barbara',
  '06085': 'Santa Clara',
  '06087': 'Santa Cruz',
  '06089': 'Shasta',
  '06091': 'Sierra',
  '06093': 'Siskiyou',
  '06095': 'Solano',
  '06097': 'Sonoma',
  '06099': 'Stanislaus',
  '06101': 'Sutter',
  '06103': 'Tehama',
  '06105': 'Trinity',
  '06107': 'Tulare',
  '06109': 'Tuolumne',
  '06111': 'Ventura',
  '06113': 'Yolo',
  '06115': 'Yuba',
  '08001': 'Adams',
  '08003': 'Alamosa',
  '08005': 'Arapahoe',
  '08007': 'Archuleta',
  '08009': 'Baca',
  '08011': 'Bent',
  '08013': 'Boulder',
  '08014': 'Broomfield',
  '08015': 'Chaffee',
  '08017': 'Cheyenne',
  '08019': 'Clear Creek',
  '08021': 'Conejos',
  '08023': 'Costilla',
  '08025': 'Crowley',
  '08027': 'Custer',
  '08029': 'Delta',
  '08031': 'Denver',
  '08033': 'Dolores',
  '08035': 'Douglas',
  '08037': 'Eagle',
  '08039': 'Elbert',
  '08041': 'El Paso',
  '08043': 'Fremont',
  '08045': 'Garfield',
  '08047': 'Gilpin',
  '08049': 'Grand',
  '08051': 'Gunnison',
  '08053': 'Hinsdale',
  '08055': 'Huerfano',
  '08057': 'Jackson',
  '08059': 'Jefferson',
  '08061': 'Kiowa',
  '08063': 'Kit Carson',
  '08065': 'Lake',
  '08067': 'La Plata',
  '08069': 'Larimer',
  '08071': 'Las Animas',
  '08073': 'Lincoln',
  '08075': 'Logan',
  '08077': 'Mesa',
  '08079': 'Mineral',
  '08081': 'Moffat',
  '08083': 'Montezuma',
  '08085': 'Montrose',
  '08087': 'Morgan',
  '08089': 'Otero',
  '08091': 'Ouray',
  '08093': 'Park',
  '08095': 'Phillips',
  '08097': 'Pitkin',
  '08099': 'Prowers',
  '08101': 'Pueblo',
  '08103': 'Rio Blanco',
  '08105': 'Rio Grande',
  '08107': 'Routt',
  '08109': 'Saguache',
  '08111': 'San Juan',
  '08113': 'San Miguel',
  '08115': 'Sedgwick',
  '08117': 'Summit',
  '08119': 'Teller',
  '08121': 'Washington',
  '08123': 'Weld',
  '08125': 'Yuma',
  '09001': 'Fairfield',
  '09003': 'Hartford',
  '09005': 'Litchfield',
  '09007': 'Middlesex',
  '09009': 'New Haven',
  '09011': 'New London',
  '09013': 'Tolland',
  '09015': 'Windham',
  10001: 'Kent',
  10003: 'New Castle',
  10005: 'Sussex',
  11001: 'District of Columbia',
  12001: 'Alachua',
  12003: 'Baker',
  12005: 'Bay',
  12007: 'Bradford',
  12009: 'Brevard',
  12011: 'Broward',
  12013: 'Calhoun',
  12015: 'Charlotte',
  12017: 'Citrus',
  12019: 'Clay',
  12021: 'Collier',
  12023: 'Columbia',
  12027: 'DeSoto',
  12029: 'Dixie',
  12031: 'Duval',
  12033: 'Escambia',
  12035: 'Flagler',
  12037: 'Franklin',
  12039: 'Gadsden',
  12041: 'Gilchrist',
  12043: 'Glades',
  12045: 'Gulf',
  12047: 'Hamilton',
  12049: 'Hardee',
  12051: 'Hendry',
  12053: 'Hernando',
  12055: 'Highlands',
  12057: 'Hillsborough',
  12059: 'Holmes',
  12061: 'Indian River',
  12063: 'Jackson',
  12065: 'Jefferson',
  12067: 'Lafayette',
  12069: 'Lake',
  12071: 'Lee',
  12073: 'Leon',
  12075: 'Levy',
  12077: 'Liberty',
  12079: 'Madison',
  12081: 'Manatee',
  12083: 'Marion',
  12085: 'Martin',
  12086: 'Miami-Dade',
  12087: 'Monroe',
  12089: 'Nassau',
  12091: 'Okaloosa',
  12093: 'Okeechobee',
  12095: 'Orange',
  12097: 'Osceola',
  12099: 'Palm Beach',
  12101: 'Pasco',
  12103: 'Pinellas',
  12105: 'Polk',
  12107: 'Putnam',
  12109: 'St. Johns',
  12111: 'St. Lucie',
  12113: 'Santa Rosa',
  12115: 'Sarasota',
  12117: 'Seminole',
  12119: 'Sumter',
  12121: 'Suwannee',
  12123: 'Taylor',
  12125: 'Union',
  12127: 'Volusia',
  12129: 'Wakulla',
  12131: 'Walton',
  12133: 'Washington',
  13001: 'Appling',
  13003: 'Atkinson',
  13005: 'Bacon',
  13007: 'Baker',
  13009: 'Baldwin',
  13011: 'Banks',
  13013: 'Barrow',
  13015: 'Bartow',
  13017: 'Ben Hill',
  13019: 'Berrien',
  13021: 'Bibb',
  13023: 'Bleckley',
  13025: 'Brantley',
  13027: 'Brooks',
  13029: 'Bryan',
  13031: 'Bulloch',
  13033: 'Burke',
  13035: 'Butts',
  13037: 'Calhoun',
  13039: 'Camden',
  13043: 'Candler',
  13045: 'Carroll',
  13047: 'Catoosa',
  13049: 'Charlton',
  13051: 'Chatham',
  13053: 'Chattahoochee',
  13055: 'Chattooga',
  13057: 'Cherokee',
  13059: 'Clarke',
  13061: 'Clay',
  13063: 'Clayton',
  13065: 'Clinch',
  13067: 'Cobb',
  13069: 'Coffee',
  13071: 'Colquitt',
  13073: 'Columbia',
  13075: 'Cook',
  13077: 'Coweta',
  13079: 'Crawford',
  13081: 'Crisp',
  13083: 'Dade',
  13085: 'Dawson',
  13087: 'Decatur',
  13089: 'DeKalb',
  13091: 'Dodge',
  13093: 'Dooly',
  13095: 'Dougherty',
  13097: 'Douglas',
  13099: 'Early',
  13101: 'Echols',
  13103: 'Effingham',
  13105: 'Elbert',
  13107: 'Emanuel',
  13109: 'Evans',
  13111: 'Fannin',
  13113: 'Fayette',
  13115: 'Floyd',
  13117: 'Forsyth',
  13119: 'Franklin',
  13121: 'Fulton',
  13123: 'Gilmer',
  13125: 'Glascock',
  13127: 'Glynn',
  13129: 'Gordon',
  13131: 'Grady',
  13133: 'Greene',
  13135: 'Gwinnett',
  13137: 'Habersham',
  13139: 'Hall',
  13141: 'Hancock',
  13143: 'Haralson',
  13145: 'Harris',
  13147: 'Hart',
  13149: 'Heard',
  13151: 'Henry',
  13153: 'Houston',
  13155: 'Irwin',
  13157: 'Jackson',
  13159: 'Jasper',
  13161: 'Jeff Davis',
  13163: 'Jefferson',
  13165: 'Jenkins',
  13167: 'Johnson',
  13169: 'Jones',
  13171: 'Lamar',
  13173: 'Lanier',
  13175: 'Laurens',
  13177: 'Lee',
  13179: 'Liberty',
  13181: 'Lincoln',
  13183: 'Long',
  13185: 'Lowndes',
  13187: 'Lumpkin',
  13189: 'McDuffie',
  13191: 'McIntosh',
  13193: 'Macon',
  13195: 'Madison',
  13197: 'Marion',
  13199: 'Meriwether',
  13201: 'Miller',
  13205: 'Mitchell',
  13207: 'Monroe',
  13209: 'Montgomery',
  13211: 'Morgan',
  13213: 'Murray',
  13215: 'Muscogee',
  13217: 'Newton',
  13219: 'Oconee',
  13221: 'Oglethorpe',
  13223: 'Paulding',
  13225: 'Peach',
  13227: 'Pickens',
  13229: 'Pierce',
  13231: 'Pike',
  13233: 'Polk',
  13235: 'Pulaski',
  13237: 'Putnam',
  13239: 'Quitman',
  13241: 'Rabun',
  13243: 'Randolph',
  13245: 'Richmond',
  13247: 'Rockdale',
  13249: 'Schley',
  13251: 'Screven',
  13253: 'Seminole',
  13255: 'Spalding',
  13257: 'Stephens',
  13259: 'Stewart',
  13261: 'Sumter',
  13263: 'Talbot',
  13265: 'Taliaferro',
  13267: 'Tattnall',
  13269: 'Taylor',
  13271: 'Telfair',
  13273: 'Terrell',
  13275: 'Thomas',
  13277: 'Tift',
  13279: 'Toombs',
  13281: 'Towns',
  13283: 'Treutlen',
  13285: 'Troup',
  13287: 'Turner',
  13289: 'Twiggs',
  13291: 'Union',
  13293: 'Upson',
  13295: 'Walker',
  13297: 'Walton',
  13299: 'Ware',
  13301: 'Warren',
  13303: 'Washington',
  13305: 'Wayne',
  13307: 'Webster',
  13309: 'Wheeler',
  13311: 'White',
  13313: 'Whitfield',
  13315: 'Wilcox',
  13317: 'Wilkes',
  13319: 'Wilkinson',
  13321: 'Worth',
  15001: 'Hawaii',
  15003: 'Honolulu',
  15005: 'Kalawao',
  15007: 'Kauai',
  15009: 'Maui',
  16001: 'Ada',
  16003: 'Adams',
  16005: 'Bannock',
  16007: 'Bear Lake',
  16009: 'Benewah',
  16011: 'Bingham',
  16013: 'Blaine',
  16015: 'Boise',
  16017: 'Bonner',
  16019: 'Bonneville',
  16021: 'Boundary',
  16023: 'Butte',
  16025: 'Camas',
  16027: 'Canyon',
  16029: 'Caribou',
  16031: 'Cassia',
  16033: 'Clark',
  16035: 'Clearwater',
  16037: 'Custer',
  16039: 'Elmore',
  16041: 'Franklin',
  16043: 'Fremont',
  16045: 'Gem',
  16047: 'Gooding',
  16049: 'Idaho',
  16051: 'Jefferson',
  16053: 'Jerome',
  16055: 'Kootenai',
  16057: 'Latah',
  16059: 'Lemhi',
  16061: 'Lewis',
  16063: 'Lincoln',
  16065: 'Madison',
  16067: 'Minidoka',
  16069: 'Nez Perce',
  16071: 'Oneida',
  16073: 'Owyhee',
  16075: 'Payette',
  16077: 'Power',
  16079: 'Shoshone',
  16081: 'Teton',
  16083: 'Twin Falls',
  16085: 'Valley',
  16087: 'Washington',
  17001: 'Adams',
  17003: 'Alexander',
  17005: 'Bond',
  17007: 'Boone',
  17009: 'Brown',
  17011: 'Bureau',
  17013: 'Calhoun',
  17015: 'Carroll',
  17017: 'Cass',
  17019: 'Champaign',
  17021: 'Christian',
  17023: 'Clark',
  17025: 'Clay',
  17027: 'Clinton',
  17029: 'Coles',
  17031: 'Cook',
  17033: 'Crawford',
  17035: 'Cumberland',
  17037: 'DeKalb',
  17039: 'De Witt',
  17041: 'Douglas',
  17043: 'DuPage',
  17045: 'Edgar',
  17047: 'Edwards',
  17049: 'Effingham',
  17051: 'Fayette',
  17053: 'Ford',
  17055: 'Franklin',
  17057: 'Fulton',
  17059: 'Gallatin',
  17061: 'Greene',
  17063: 'Grundy',
  17065: 'Hamilton',
  17067: 'Hancock',
  17069: 'Hardin',
  17071: 'Henderson',
  17073: 'Henry',
  17075: 'Iroquois',
  17077: 'Jackson',
  17079: 'Jasper',
  17081: 'Jefferson',
  17083: 'Jersey',
  17085: 'Jo Daviess',
  17087: 'Johnson',
  17089: 'Kane',
  17091: 'Kankakee',
  17093: 'Kendall',
  17095: 'Knox',
  17097: 'Lake',
  17099: 'LaSalle',
  17101: 'Lawrence',
  17103: 'Lee',
  17105: 'Livingston',
  17107: 'Logan',
  17109: 'McDonough',
  17111: 'McHenry',
  17113: 'McLean',
  17115: 'Macon',
  17117: 'Macoupin',
  17119: 'Madison',
  17121: 'Marion',
  17123: 'Marshall',
  17125: 'Mason',
  17127: 'Massac',
  17129: 'Menard',
  17131: 'Mercer',
  17133: 'Monroe',
  17135: 'Montgomery',
  17137: 'Morgan',
  17139: 'Moultrie',
  17141: 'Ogle',
  17143: 'Peoria',
  17145: 'Perry',
  17147: 'Piatt',
  17149: 'Pike',
  17151: 'Pope',
  17153: 'Pulaski',
  17155: 'Putnam',
  17157: 'Randolph',
  17159: 'Richland',
  17161: 'Rock Island',
  17163: 'St. Clair',
  17165: 'Saline',
  17167: 'Sangamon',
  17169: 'Schuyler',
  17171: 'Scott',
  17173: 'Shelby',
  17175: 'Stark',
  17177: 'Stephenson',
  17179: 'Tazewell',
  17181: 'Union',
  17183: 'Vermilion',
  17185: 'Wabash',
  17187: 'Warren',
  17189: 'Washington',
  17191: 'Wayne',
  17193: 'White',
  17195: 'Whiteside',
  17197: 'Will',
  17199: 'Williamson',
  17201: 'Winnebago',
  17203: 'Woodford',
  18001: 'Adams',
  18003: 'Allen',
  18005: 'Bartholomew',
  18007: 'Benton',
  18009: 'Blackford',
  18011: 'Boone',
  18013: 'Brown',
  18015: 'Carroll',
  18017: 'Cass',
  18019: 'Clark',
  18021: 'Clay',
  18023: 'Clinton',
  18025: 'Crawford',
  18027: 'Daviess',
  18029: 'Dearborn',
  18031: 'Decatur',
  18033: 'DeKalb',
  18035: 'Delaware',
  18037: 'Dubois',
  18039: 'Elkhart',
  18041: 'Fayette',
  18043: 'Floyd',
  18045: 'Fountain',
  18047: 'Franklin',
  18049: 'Fulton',
  18051: 'Gibson',
  18053: 'Grant',
  18055: 'Greene',
  18057: 'Hamilton',
  18059: 'Hancock',
  18061: 'Harrison',
  18063: 'Hendricks',
  18065: 'Henry',
  18067: 'Howard',
  18069: 'Huntington',
  18071: 'Jackson',
  18073: 'Jasper',
  18075: 'Jay',
  18077: 'Jefferson',
  18079: 'Jennings',
  18081: 'Johnson',
  18083: 'Knox',
  18085: 'Kosciusko',
  18087: 'LaGrange',
  18089: 'Lake',
  18091: 'LaPorte',
  18093: 'Lawrence',
  18095: 'Madison',
  18097: 'Marion',
  18099: 'Marshall',
  18101: 'Martin',
  18103: 'Miami',
  18105: 'Monroe',
  18107: 'Montgomery',
  18109: 'Morgan',
  18111: 'Newton',
  18113: 'Noble',
  18115: 'Ohio',
  18117: 'Orange',
  18119: 'Owen',
  18121: 'Parke',
  18123: 'Perry',
  18125: 'Pike',
  18127: 'Porter',
  18129: 'Posey',
  18131: 'Pulaski',
  18133: 'Putnam',
  18135: 'Randolph',
  18137: 'Ripley',
  18139: 'Rush',
  18141: 'St. Joseph',
  18143: 'Scott',
  18145: 'Shelby',
  18147: 'Spencer',
  18149: 'Starke',
  18151: 'Steuben',
  18153: 'Sullivan',
  18155: 'Switzerland',
  18157: 'Tippecanoe',
  18159: 'Tipton',
  18161: 'Union',
  18163: 'Vanderburgh',
  18165: 'Vermillion',
  18167: 'Vigo',
  18169: 'Wabash',
  18171: 'Warren',
  18173: 'Warrick',
  18175: 'Washington',
  18177: 'Wayne',
  18179: 'Wells',
  18181: 'White',
  18183: 'Whitley',
  19001: 'Adair',
  19003: 'Adams',
  19005: 'Allamakee',
  19007: 'Appanoose',
  19009: 'Audubon',
  19011: 'Benton',
  19013: 'Black Hawk',
  19015: 'Boone',
  19017: 'Bremer',
  19019: 'Buchanan',
  19021: 'Buena Vista',
  19023: 'Butler',
  19025: 'Calhoun',
  19027: 'Carroll',
  19029: 'Cass',
  19031: 'Cedar',
  19033: 'Cerro Gordo',
  19035: 'Cherokee',
  19037: 'Chickasaw',
  19039: 'Clarke',
  19041: 'Clay',
  19043: 'Clayton',
  19045: 'Clinton',
  19047: 'Crawford',
  19049: 'Dallas',
  19051: 'Davis',
  19053: 'Decatur',
  19055: 'Delaware',
  19057: 'Des Moines',
  19059: 'Dickinson',
  19061: 'Dubuque',
  19063: 'Emmet',
  19065: 'Fayette',
  19067: 'Floyd',
  19069: 'Franklin',
  19071: 'Fremont',
  19073: 'Greene',
  19075: 'Grundy',
  19077: 'Guthrie',
  19079: 'Hamilton',
  19081: 'Hancock',
  19083: 'Hardin',
  19085: 'Harrison',
  19087: 'Henry',
  19089: 'Howard',
  19091: 'Humboldt',
  19093: 'Ida',
  19095: 'Iowa',
  19097: 'Jackson',
  19099: 'Jasper',
  19101: 'Jefferson',
  19103: 'Johnson',
  19105: 'Jones',
  19107: 'Keokuk',
  19109: 'Kossuth',
  19111: 'Lee',
  19113: 'Linn',
  19115: 'Louisa',
  19117: 'Lucas',
  19119: 'Lyon',
  19121: 'Madison',
  19123: 'Mahaska',
  19125: 'Marion',
  19127: 'Marshall',
  19129: 'Mills',
  19131: 'Mitchell',
  19133: 'Monona',
  19135: 'Monroe',
  19137: 'Montgomery',
  19139: 'Muscatine',
  19141: "O'Brien",
  19143: 'Osceola',
  19145: 'Page',
  19147: 'Palo Alto',
  19149: 'Plymouth',
  19151: 'Pocahontas',
  19153: 'Polk',
  19155: 'Pottawattamie',
  19157: 'Poweshiek',
  19159: 'Ringgold',
  19161: 'Sac',
  19163: 'Scott',
  19165: 'Shelby',
  19167: 'Sioux',
  19169: 'Story',
  19171: 'Tama',
  19173: 'Taylor',
  19175: 'Union',
  19177: 'Van Buren',
  19179: 'Wapello',
  19181: 'Warren',
  19183: 'Washington',
  19185: 'Wayne',
  19187: 'Webster',
  19189: 'Winnebago',
  19191: 'Winneshiek',
  19193: 'Woodbury',
  19195: 'Worth',
  19197: 'Wright',
  20001: 'Allen',
  20003: 'Anderson',
  20005: 'Atchison',
  20007: 'Barber',
  20009: 'Barton',
  20011: 'Bourbon',
  20013: 'Brown',
  20015: 'Butler',
  20017: 'Chase',
  20019: 'Chautauqua',
  20021: 'Cherokee',
  20023: 'Cheyenne',
  20025: 'Clark',
  20027: 'Clay',
  20029: 'Cloud',
  20031: 'Coffey',
  20033: 'Comanche',
  20035: 'Cowley',
  20037: 'Crawford',
  20039: 'Decatur',
  20041: 'Dickinson',
  20043: 'Doniphan',
  20045: 'Douglas',
  20047: 'Edwards',
  20049: 'Elk',
  20051: 'Ellis',
  20053: 'Ellsworth',
  20055: 'Finney',
  20057: 'Ford',
  20059: 'Franklin',
  20061: 'Geary',
  20063: 'Gove',
  20065: 'Graham',
  20067: 'Grant',
  20069: 'Gray',
  20071: 'Greeley',
  20073: 'Greenwood',
  20075: 'Hamilton',
  20077: 'Harper',
  20079: 'Harvey',
  20081: 'Haskell',
  20083: 'Hodgeman',
  20085: 'Jackson',
  20087: 'Jefferson',
  20089: 'Jewell',
  20091: 'Johnson',
  20093: 'Kearny',
  20095: 'Kingman',
  20097: 'Kiowa',
  20099: 'Labette',
  20101: 'Lane',
  20103: 'Leavenworth',
  20105: 'Lincoln',
  20107: 'Linn',
  20109: 'Logan',
  20111: 'Lyon',
  20113: 'McPherson',
  20115: 'Marion',
  20117: 'Marshall',
  20119: 'Meade',
  20121: 'Miami',
  20123: 'Mitchell',
  20125: 'Montgomery',
  20127: 'Morris',
  20129: 'Morton',
  20131: 'Nemaha',
  20133: 'Neosho',
  20135: 'Ness',
  20137: 'Norton',
  20139: 'Osage',
  20141: 'Osborne',
  20143: 'Ottawa',
  20145: 'Pawnee',
  20147: 'Phillips',
  20149: 'Pottawatomie',
  20151: 'Pratt',
  20153: 'Rawlins',
  20155: 'Reno',
  20157: 'Republic',
  20159: 'Rice',
  20161: 'Riley',
  20163: 'Rooks',
  20165: 'Rush',
  20167: 'Russell',
  20169: 'Saline',
  20171: 'Scott',
  20173: 'Sedgwick',
  20175: 'Seward',
  20177: 'Shawnee',
  20179: 'Sheridan',
  20181: 'Sherman',
  20183: 'Smith',
  20185: 'Stafford',
  20187: 'Stanton',
  20189: 'Stevens',
  20191: 'Sumner',
  20193: 'Thomas',
  20195: 'Trego',
  20197: 'Wabaunsee',
  20199: 'Wallace',
  20201: 'Washington',
  20203: 'Wichita',
  20205: 'Wilson',
  20207: 'Woodson',
  20209: 'Wyandotte',
  21001: 'Adair',
  21003: 'Allen',
  21005: 'Anderson',
  21007: 'Ballard',
  21009: 'Barren',
  21011: 'Bath',
  21013: 'Bell',
  21015: 'Boone',
  21017: 'Bourbon',
  21019: 'Boyd',
  21021: 'Boyle',
  21023: 'Bracken',
  21025: 'Breathitt',
  21027: 'Breckinridge',
  21029: 'Bullitt',
  21031: 'Butler',
  21033: 'Caldwell',
  21035: 'Calloway',
  21037: 'Campbell',
  21039: 'Carlisle',
  21041: 'Carroll',
  21043: 'Carter',
  21045: 'Casey',
  21047: 'Christian',
  21049: 'Clark',
  21051: 'Clay',
  21053: 'Clinton',
  21055: 'Crittenden',
  21057: 'Cumberland',
  21059: 'Daviess',
  21061: 'Edmonson',
  21063: 'Elliott',
  21065: 'Estill',
  21067: 'Fayette',
  21069: 'Fleming',
  21071: 'Floyd',
  21073: 'Franklin',
  21075: 'Fulton',
  21077: 'Gallatin',
  21079: 'Garrard',
  21081: 'Grant',
  21083: 'Graves',
  21085: 'Grayson',
  21087: 'Green',
  21089: 'Greenup',
  21091: 'Hancock',
  21093: 'Hardin',
  21095: 'Harlan',
  21097: 'Harrison',
  21099: 'Hart',
  21101: 'Henderson',
  21103: 'Henry',
  21105: 'Hickman',
  21107: 'Hopkins',
  21109: 'Jackson',
  21111: 'Jefferson',
  21113: 'Jessamine',
  21115: 'Johnson',
  21117: 'Kenton',
  21119: 'Knott',
  21121: 'Knox',
  21123: 'Larue',
  21125: 'Laurel',
  21127: 'Lawrence',
  21129: 'Lee',
  21131: 'Leslie',
  21133: 'Letcher',
  21135: 'Lewis',
  21137: 'Lincoln',
  21139: 'Livingston',
  21141: 'Logan',
  21143: 'Lyon',
  21145: 'McCracken',
  21147: 'McCreary',
  21149: 'McLean',
  21151: 'Madison',
  21153: 'Magoffin',
  21155: 'Marion',
  21157: 'Marshall',
  21159: 'Martin',
  21161: 'Mason',
  21163: 'Meade',
  21165: 'Menifee',
  21167: 'Mercer',
  21169: 'Metcalfe',
  21171: 'Monroe',
  21173: 'Montgomery',
  21175: 'Morgan',
  21177: 'Muhlenberg',
  21179: 'Nelson',
  21181: 'Nicholas',
  21183: 'Ohio',
  21185: 'Oldham',
  21187: 'Owen',
  21189: 'Owsley',
  21191: 'Pendleton',
  21193: 'Perry',
  21195: 'Pike',
  21197: 'Powell',
  21199: 'Pulaski',
  21201: 'Robertson',
  21203: 'Rockcastle',
  21205: 'Rowan',
  21207: 'Russell',
  21209: 'Scott',
  21211: 'Shelby',
  21213: 'Simpson',
  21215: 'Spencer',
  21217: 'Taylor',
  21219: 'Todd',
  21221: 'Trigg',
  21223: 'Trimble',
  21225: 'Union',
  21227: 'Warren',
  21229: 'Washington',
  21231: 'Wayne',
  21233: 'Webster',
  21235: 'Whitley',
  21237: 'Wolfe',
  21239: 'Woodford',
  22001: 'Acadia',
  22003: 'Allen',
  22005: 'Ascension',
  22007: 'Assumption',
  22009: 'Avoyelles',
  22011: 'Beauregard',
  22013: 'Bienville',
  22015: 'Bossier',
  22017: 'Caddo',
  22019: 'Calcasieu',
  22021: 'Caldwell',
  22023: 'Cameron',
  22025: 'Catahoula',
  22027: 'Claiborne',
  22029: 'Concordia',
  22031: 'De Soto',
  22033: 'East Baton Rouge',
  22035: 'East Carroll',
  22037: 'East Feliciana',
  22039: 'Evangeline',
  22041: 'Franklin',
  22043: 'Grant',
  22045: 'Iberia',
  22047: 'Iberville',
  22049: 'Jackson',
  22051: 'Jefferson',
  22053: 'Jefferson Davis',
  22055: 'Lafayette',
  22057: 'Lafourche',
  22059: 'LaSalle',
  22061: 'Lincoln',
  22063: 'Livingston',
  22065: 'Madison',
  22067: 'Morehouse',
  22069: 'Natchitoches',
  22071: 'Orleans',
  22073: 'Ouachita',
  22075: 'Plaquemines',
  22077: 'Pointe Coupee',
  22079: 'Rapides',
  22081: 'Red River',
  22083: 'Richland',
  22085: 'Sabine',
  22087: 'St. Bernard',
  22089: 'St. Charles',
  22091: 'St. Helena',
  22093: 'St. James',
  22095: 'St. John the Baptist',
  22097: 'St. Landry',
  22099: 'St. Martin',
  22101: 'St. Mary',
  22103: 'St. Tammany',
  22105: 'Tangipahoa',
  22107: 'Tensas',
  22109: 'Terrebonne',
  22111: 'Union',
  22113: 'Vermilion',
  22115: 'Vernon',
  22117: 'Washington',
  22119: 'Webster',
  22121: 'West Baton Rouge',
  22123: 'West Carroll',
  22125: 'West Feliciana',
  22127: 'Winn',
  23001: 'Androscoggin',
  23003: 'Aroostook',
  23005: 'Cumberland',
  23007: 'Franklin',
  23009: 'Hancock',
  23011: 'Kennebec',
  23013: 'Knox',
  23015: 'Lincoln',
  23017: 'Oxford',
  23019: 'Penobscot',
  23021: 'Piscataquis',
  23023: 'Sagadahoc',
  23025: 'Somerset',
  23027: 'Waldo',
  23029: 'Washington',
  23031: 'York',
  24001: 'Allegany',
  24003: 'Anne Arundel',
  24005: 'Baltimore',
  24009: 'Calvert',
  24011: 'Caroline',
  24013: 'Carroll',
  24015: 'Cecil',
  24017: 'Charles',
  24019: 'Dorchester',
  24021: 'Frederick',
  24023: 'Garrett',
  24025: 'Harford',
  24027: 'Howard',
  24029: 'Kent',
  24031: 'Montgomery',
  24033: "Prince George's",
  24035: "Queen Anne's",
  24037: "St. Mary's",
  24039: 'Somerset',
  24041: 'Talbot',
  24043: 'Washington',
  24045: 'Wicomico',
  24047: 'Worcester',
  24510: 'Baltimore',
  25001: 'Barnstable',
  25003: 'Berkshire',
  25005: 'Bristol',
  25007: 'Dukes',
  25009: 'Essex',
  25011: 'Franklin',
  25013: 'Hampden',
  25015: 'Hampshire',
  25017: 'Middlesex',
  25019: 'Nantucket',
  25021: 'Norfolk',
  25023: 'Plymouth',
  25025: 'Suffolk',
  25027: 'Worcester',
  26001: 'Alcona',
  26003: 'Alger',
  26005: 'Allegan',
  26007: 'Alpena',
  26009: 'Antrim',
  26011: 'Arenac',
  26013: 'Baraga',
  26015: 'Barry',
  26017: 'Bay',
  26019: 'Benzie',
  26021: 'Berrien',
  26023: 'Branch',
  26025: 'Calhoun',
  26027: 'Cass',
  26029: 'Charlevoix',
  26031: 'Cheboygan',
  26033: 'Chippewa',
  26035: 'Clare',
  26037: 'Clinton',
  26039: 'Crawford',
  26041: 'Delta',
  26043: 'Dickinson',
  26045: 'Eaton',
  26047: 'Emmet',
  26049: 'Genesee',
  26051: 'Gladwin',
  26053: 'Gogebic',
  26055: 'Grand Traverse',
  26057: 'Gratiot',
  26059: 'Hillsdale',
  26061: 'Houghton',
  26063: 'Huron',
  26065: 'Ingham',
  26067: 'Ionia',
  26069: 'Iosco',
  26071: 'Iron',
  26073: 'Isabella',
  26075: 'Jackson',
  26077: 'Kalamazoo',
  26079: 'Kalkaska',
  26081: 'Kent',
  26083: 'Keweenaw',
  26085: 'Lake',
  26087: 'Lapeer',
  26089: 'Leelanau',
  26091: 'Lenawee',
  26093: 'Livingston',
  26095: 'Luce',
  26097: 'Mackinac',
  26099: 'Macomb',
  26101: 'Manistee',
  26103: 'Marquette',
  26105: 'Mason',
  26107: 'Mecosta',
  26109: 'Menominee',
  26111: 'Midland',
  26113: 'Missaukee',
  26115: 'Monroe',
  26117: 'Montcalm',
  26119: 'Montmorency',
  26121: 'Muskegon',
  26123: 'Newaygo',
  26125: 'Oakland',
  26127: 'Oceana',
  26129: 'Ogemaw',
  26131: 'Ontonagon',
  26133: 'Osceola',
  26135: 'Oscoda',
  26137: 'Otsego',
  26139: 'Ottawa',
  26141: 'Presque Isle',
  26143: 'Roscommon',
  26145: 'Saginaw',
  26147: 'St. Clair',
  26149: 'St. Joseph',
  26151: 'Sanilac',
  26153: 'Schoolcraft',
  26155: 'Shiawassee',
  26157: 'Tuscola',
  26159: 'Van Buren',
  26161: 'Washtenaw',
  26163: 'Wayne',
  26165: 'Wexford',
  27001: 'Aitkin',
  27003: 'Anoka',
  27005: 'Becker',
  27007: 'Beltrami',
  27009: 'Benton',
  27011: 'Big Stone',
  27013: 'Blue Earth',
  27015: 'Brown',
  27017: 'Carlton',
  27019: 'Carver',
  27021: 'Cass',
  27023: 'Chippewa',
  27025: 'Chisago',
  27027: 'Clay',
  27029: 'Clearwater',
  27031: 'Cook',
  27033: 'Cottonwood',
  27035: 'Crow Wing',
  27037: 'Dakota',
  27039: 'Dodge',
  27041: 'Douglas',
  27043: 'Faribault',
  27045: 'Fillmore',
  27047: 'Freeborn',
  27049: 'Goodhue',
  27051: 'Grant',
  27053: 'Hennepin',
  27055: 'Houston',
  27057: 'Hubbard',
  27059: 'Isanti',
  27061: 'Itasca',
  27063: 'Jackson',
  27065: 'Kanabec',
  27067: 'Kandiyohi',
  27069: 'Kittson',
  27071: 'Koochiching',
  27073: 'Lac qui Parle',
  27075: 'Lake',
  27077: 'Lake of the Woods',
  27079: 'Le Sueur',
  27081: 'Lincoln',
  27083: 'Lyon',
  27085: 'McLeod',
  27087: 'Mahnomen',
  27089: 'Marshall',
  27091: 'Martin',
  27093: 'Meeker',
  27095: 'Mille Lacs',
  27097: 'Morrison',
  27099: 'Mower',
  27101: 'Murray',
  27103: 'Nicollet',
  27105: 'Nobles',
  27107: 'Norman',
  27109: 'Olmsted',
  27111: 'Otter Tail',
  27113: 'Pennington',
  27115: 'Pine',
  27117: 'Pipestone',
  27119: 'Polk',
  27121: 'Pope',
  27123: 'Ramsey',
  27125: 'Red Lake',
  27127: 'Redwood',
  27129: 'Renville',
  27131: 'Rice',
  27133: 'Rock',
  27135: 'Roseau',
  27137: 'St. Louis',
  27139: 'Scott',
  27141: 'Sherburne',
  27143: 'Sibley',
  27145: 'Stearns',
  27147: 'Steele',
  27149: 'Stevens',
  27151: 'Swift',
  27153: 'Todd',
  27155: 'Traverse',
  27157: 'Wabasha',
  27159: 'Wadena',
  27161: 'Waseca',
  27163: 'Washington',
  27165: 'Watonwan',
  27167: 'Wilkin',
  27169: 'Winona',
  27171: 'Wright',
  27173: 'Yellow Medicine',
  28001: 'Adams',
  28003: 'Alcorn',
  28005: 'Amite',
  28007: 'Attala',
  28009: 'Benton',
  28011: 'Bolivar',
  28013: 'Calhoun',
  28015: 'Carroll',
  28017: 'Chickasaw',
  28019: 'Choctaw',
  28021: 'Claiborne',
  28023: 'Clarke',
  28025: 'Clay',
  28027: 'Coahoma',
  28029: 'Copiah',
  28031: 'Covington',
  28033: 'DeSoto',
  28035: 'Forrest',
  28037: 'Franklin',
  28039: 'George',
  28041: 'Greene',
  28043: 'Grenada',
  28045: 'Hancock',
  28047: 'Harrison',
  28049: 'Hinds',
  28051: 'Holmes',
  28053: 'Humphreys',
  28055: 'Issaquena',
  28057: 'Itawamba',
  28059: 'Jackson',
  28061: 'Jasper',
  28063: 'Jefferson',
  28065: 'Jefferson Davis',
  28067: 'Jones',
  28069: 'Kemper',
  28071: 'Lafayette',
  28073: 'Lamar',
  28075: 'Lauderdale',
  28077: 'Lawrence',
  28079: 'Leake',
  28081: 'Lee',
  28083: 'Leflore',
  28085: 'Lincoln',
  28087: 'Lowndes',
  28089: 'Madison',
  28091: 'Marion',
  28093: 'Marshall',
  28095: 'Monroe',
  28097: 'Montgomery',
  28099: 'Neshoba',
  28101: 'Newton',
  28103: 'Noxubee',
  28105: 'Oktibbeha',
  28107: 'Panola',
  28109: 'Pearl River',
  28111: 'Perry',
  28113: 'Pike',
  28115: 'Pontotoc',
  28117: 'Prentiss',
  28119: 'Quitman',
  28121: 'Rankin',
  28123: 'Scott',
  28125: 'Sharkey',
  28127: 'Simpson',
  28129: 'Smith',
  28131: 'Stone',
  28133: 'Sunflower',
  28135: 'Tallahatchie',
  28137: 'Tate',
  28139: 'Tippah',
  28141: 'Tishomingo',
  28143: 'Tunica',
  28145: 'Union',
  28147: 'Walthall',
  28149: 'Warren',
  28151: 'Washington',
  28153: 'Wayne',
  28155: 'Webster',
  28157: 'Wilkinson',
  28159: 'Winston',
  28161: 'Yalobusha',
  28163: 'Yazoo',
  29001: 'Adair',
  29003: 'Andrew',
  29005: 'Atchison',
  29007: 'Audrain',
  29009: 'Barry',
  29011: 'Barton',
  29013: 'Bates',
  29015: 'Benton',
  29017: 'Bollinger',
  29019: 'Boone',
  29021: 'Buchanan',
  29023: 'Butler',
  29025: 'Caldwell',
  29027: 'Callaway',
  29029: 'Camden',
  29031: 'Cape Girardeau',
  29033: 'Carroll',
  29035: 'Carter',
  29037: 'Cass',
  29039: 'Cedar',
  29041: 'Chariton',
  29043: 'Christian',
  29045: 'Clark',
  29047: 'Clay',
  29049: 'Clinton',
  29051: 'Cole',
  29053: 'Cooper',
  29055: 'Crawford',
  29057: 'Dade',
  29059: 'Dallas',
  29061: 'Daviess',
  29063: 'DeKalb',
  29065: 'Dent',
  29067: 'Douglas',
  29069: 'Dunklin',
  29071: 'Franklin',
  29073: 'Gasconade',
  29075: 'Gentry',
  29077: 'Greene',
  29079: 'Grundy',
  29081: 'Harrison',
  29083: 'Henry',
  29085: 'Hickory',
  29087: 'Holt',
  29089: 'Howard',
  29091: 'Howell',
  29093: 'Iron',
  29095: 'Jackson',
  29097: 'Jasper',
  29099: 'Jefferson',
  29101: 'Johnson',
  29103: 'Knox',
  29105: 'Laclede',
  29107: 'Lafayette',
  29109: 'Lawrence',
  29111: 'Lewis',
  29113: 'Lincoln',
  29115: 'Linn',
  29117: 'Livingston',
  29119: 'McDonald',
  29121: 'Macon',
  29123: 'Madison',
  29125: 'Maries',
  29127: 'Marion',
  29129: 'Mercer',
  29131: 'Miller',
  29133: 'Mississippi',
  29135: 'Moniteau',
  29137: 'Monroe',
  29139: 'Montgomery',
  29141: 'Morgan',
  29143: 'New Madrid',
  29145: 'Newton',
  29147: 'Nodaway',
  29149: 'Oregon',
  29151: 'Osage',
  29153: 'Ozark',
  29155: 'Pemiscot',
  29157: 'Perry',
  29159: 'Pettis',
  29161: 'Phelps',
  29163: 'Pike',
  29165: 'Platte',
  29167: 'Polk',
  29169: 'Pulaski',
  29171: 'Putnam',
  29173: 'Ralls',
  29175: 'Randolph',
  29177: 'Ray',
  29179: 'Reynolds',
  29181: 'Ripley',
  29183: 'St. Charles',
  29185: 'St. Clair',
  29186: 'Ste. Genevieve',
  29187: 'St. Francois',
  29189: 'St. Louis',
  29195: 'Saline',
  29197: 'Schuyler',
  29199: 'Scotland',
  29201: 'Scott',
  29203: 'Shannon',
  29205: 'Shelby',
  29207: 'Stoddard',
  29209: 'Stone',
  29211: 'Sullivan',
  29213: 'Taney',
  29215: 'Texas',
  29217: 'Vernon',
  29219: 'Warren',
  29221: 'Washington',
  29223: 'Wayne',
  29225: 'Webster',
  29227: 'Worth',
  29229: 'Wright',
  29510: 'St. Louis City',
  30001: 'Beaverhead',
  30003: 'Big Horn',
  30005: 'Blaine',
  30007: 'Broadwater',
  30009: 'Carbon',
  30011: 'Carter',
  30013: 'Cascade',
  30015: 'Chouteau',
  30017: 'Custer',
  30019: 'Daniels',
  30021: 'Dawson',
  30023: 'Deer Lodge',
  30025: 'Fallon',
  30027: 'Fergus',
  30029: 'Flathead',
  30031: 'Gallatin',
  30033: 'Garfield',
  30035: 'Glacier',
  30037: 'Golden Valley',
  30039: 'Granite',
  30041: 'Hill',
  30043: 'Jefferson',
  30045: 'Judith Basin',
  30047: 'Lake',
  30049: 'Lewis and Clark',
  30051: 'Liberty',
  30053: 'Lincoln',
  30055: 'McCone',
  30057: 'Madison',
  30059: 'Meagher',
  30061: 'Mineral',
  30063: 'Missoula',
  30065: 'Musselshell',
  30067: 'Park',
  30069: 'Petroleum',
  30071: 'Phillips',
  30073: 'Pondera',
  30075: 'Powder River',
  30077: 'Powell',
  30079: 'Prairie',
  30081: 'Ravalli',
  30083: 'Richland',
  30085: 'Roosevelt',
  30087: 'Rosebud',
  30089: 'Sanders',
  30091: 'Sheridan',
  30093: 'Silver Bow',
  30095: 'Stillwater',
  30097: 'Sweet Grass',
  30099: 'Teton',
  30101: 'Toole',
  30103: 'Treasure',
  30105: 'Valley',
  30107: 'Wheatland',
  30109: 'Wibaux',
  30111: 'Yellowstone',
  31001: 'Adams',
  31003: 'Antelope',
  31005: 'Arthur',
  31007: 'Banner',
  31009: 'Blaine',
  31011: 'Boone',
  31013: 'Box Butte',
  31015: 'Boyd',
  31017: 'Brown',
  31019: 'Buffalo',
  31021: 'Burt',
  31023: 'Butler',
  31025: 'Cass',
  31027: 'Cedar',
  31029: 'Chase',
  31031: 'Cherry',
  31033: 'Cheyenne',
  31035: 'Clay',
  31037: 'Colfax',
  31039: 'Cuming',
  31041: 'Custer',
  31043: 'Dakota',
  31045: 'Dawes',
  31047: 'Dawson',
  31049: 'Deuel',
  31051: 'Dixon',
  31053: 'Dodge',
  31055: 'Douglas',
  31057: 'Dundy',
  31059: 'Fillmore',
  31061: 'Franklin',
  31063: 'Frontier',
  31065: 'Furnas',
  31067: 'Gage',
  31069: 'Garden',
  31071: 'Garfield',
  31073: 'Gosper',
  31075: 'Grant',
  31077: 'Greeley',
  31079: 'Hall',
  31081: 'Hamilton',
  31083: 'Harlan',
  31085: 'Hayes',
  31087: 'Hitchcock',
  31089: 'Holt',
  31091: 'Hooker',
  31093: 'Howard',
  31095: 'Jefferson',
  31097: 'Johnson',
  31099: 'Kearney',
  31101: 'Keith',
  31103: 'Keya Paha',
  31105: 'Kimball',
  31107: 'Knox',
  31109: 'Lancaster',
  31111: 'Lincoln',
  31113: 'Logan',
  31115: 'Loup',
  31117: 'McPherson',
  31119: 'Madison',
  31121: 'Merrick',
  31123: 'Morrill',
  31125: 'Nance',
  31127: 'Nemaha',
  31129: 'Nuckolls',
  31131: 'Otoe',
  31133: 'Pawnee',
  31135: 'Perkins',
  31137: 'Phelps',
  31139: 'Pierce',
  31141: 'Platte',
  31143: 'Polk',
  31145: 'Red Willow',
  31147: 'Richardson',
  31149: 'Rock',
  31151: 'Saline',
  31153: 'Sarpy',
  31155: 'Saunders',
  31157: 'Scotts Bluff',
  31159: 'Seward',
  31161: 'Sheridan',
  31163: 'Sherman',
  31165: 'Sioux',
  31167: 'Stanton',
  31169: 'Thayer',
  31171: 'Thomas',
  31173: 'Thurston',
  31175: 'Valley',
  31177: 'Washington',
  31179: 'Wayne',
  31181: 'Webster',
  31183: 'Wheeler',
  31185: 'York',
  32001: 'Churchill',
  32003: 'Clark',
  32005: 'Douglas',
  32007: 'Elko',
  32009: 'Esmeralda',
  32011: 'Eureka',
  32013: 'Humboldt',
  32015: 'Lander',
  32017: 'Lincoln',
  32019: 'Lyon',
  32021: 'Mineral',
  32023: 'Nye',
  32027: 'Pershing',
  32029: 'Storey',
  32031: 'Washoe',
  32033: 'White Pine',
  32510: 'Carson City',
  33001: 'Belknap',
  33003: 'Carroll',
  33005: 'Cheshire',
  33007: 'Coos',
  33009: 'Grafton',
  33011: 'Hillsborough',
  33013: 'Merrimack',
  33015: 'Rockingham',
  33017: 'Strafford',
  33019: 'Sullivan',
  34001: 'Atlantic',
  34003: 'Bergen',
  34005: 'Burlington',
  34007: 'Camden',
  34009: 'Cape May',
  34011: 'Cumberland',
  34013: 'Essex',
  34015: 'Gloucester',
  34017: 'Hudson',
  34019: 'Hunterdon',
  34021: 'Mercer',
  34023: 'Middlesex',
  34025: 'Monmouth',
  34027: 'Morris',
  34029: 'Ocean',
  34031: 'Passaic',
  34033: 'Salem',
  34035: 'Somerset',
  34037: 'Sussex',
  34039: 'Union',
  34041: 'Warren',
  35001: 'Bernalillo',
  35003: 'Catron',
  35005: 'Chaves',
  35006: 'Cibola',
  35007: 'Colfax',
  35009: 'Curry',
  35011: 'De Baca',
  35013: 'Doña Ana',
  35015: 'Eddy',
  35017: 'Grant',
  35019: 'Guadalupe',
  35021: 'Harding',
  35023: 'Hidalgo',
  35025: 'Lea',
  35027: 'Lincoln',
  35028: 'Los Alamos',
  35029: 'Luna',
  35031: 'McKinley',
  35033: 'Mora',
  35035: 'Otero',
  35037: 'Quay',
  35039: 'Rio Arriba',
  35041: 'Roosevelt',
  35043: 'Sandoval',
  35045: 'San Juan',
  35047: 'San Miguel',
  35049: 'Santa Fe',
  35051: 'Sierra',
  35053: 'Socorro',
  35055: 'Taos',
  35057: 'Torrance',
  35059: 'Union',
  35061: 'Valencia',
  36001: 'Albany',
  36003: 'Allegany',
  36005: 'Bronx',
  36007: 'Broome',
  36009: 'Cattaraugus',
  36011: 'Cayuga',
  36013: 'Chautauqua',
  36015: 'Chemung',
  36017: 'Chenango',
  36019: 'Clinton',
  36021: 'Columbia',
  36023: 'Cortland',
  36025: 'Delaware',
  36027: 'Dutchess',
  36029: 'Erie',
  36031: 'Essex',
  36033: 'Franklin',
  36035: 'Fulton',
  36037: 'Genesee',
  36039: 'Greene',
  36041: 'Hamilton',
  36043: 'Herkimer',
  36045: 'Jefferson',
  36047: 'Kings',
  36049: 'Lewis',
  36051: 'Livingston',
  36053: 'Madison',
  36055: 'Monroe',
  36057: 'Montgomery',
  36059: 'Nassau',
  36061: 'New York',
  36063: 'Niagara',
  36065: 'Oneida',
  36067: 'Onondaga',
  36069: 'Ontario',
  36071: 'Orange',
  36073: 'Orleans',
  36075: 'Oswego',
  36077: 'Otsego',
  36079: 'Putnam',
  36081: 'Queens',
  36083: 'Rensselaer',
  36085: 'Richmond',
  36087: 'Rockland',
  36089: 'St. Lawrence',
  36091: 'Saratoga',
  36093: 'Schenectady',
  36095: 'Schoharie',
  36097: 'Schuyler',
  36099: 'Seneca',
  36101: 'Steuben',
  36103: 'Suffolk',
  36105: 'Sullivan',
  36107: 'Tioga',
  36109: 'Tompkins',
  36111: 'Ulster',
  36113: 'Warren',
  36115: 'Washington',
  36117: 'Wayne',
  36119: 'Westchester',
  36121: 'Wyoming',
  36123: 'Yates',
  37001: 'Alamance',
  37003: 'Alexander',
  37005: 'Alleghany',
  37007: 'Anson',
  37009: 'Ashe',
  37011: 'Avery',
  37013: 'Beaufort',
  37015: 'Bertie',
  37017: 'Bladen',
  37019: 'Brunswick',
  37021: 'Buncombe',
  37023: 'Burke',
  37025: 'Cabarrus',
  37027: 'Caldwell',
  37029: 'Camden',
  37031: 'Carteret',
  37033: 'Caswell',
  37035: 'Catawba',
  37037: 'Chatham',
  37039: 'Cherokee',
  37041: 'Chowan',
  37043: 'Clay',
  37045: 'Cleveland',
  37047: 'Columbus',
  37049: 'Craven',
  37051: 'Cumberland',
  37053: 'Currituck',
  37055: 'Dare',
  37057: 'Davidson',
  37059: 'Davie',
  37061: 'Duplin',
  37063: 'Durham',
  37065: 'Edgecombe',
  37067: 'Forsyth',
  37069: 'Franklin',
  37071: 'Gaston',
  37073: 'Gates',
  37075: 'Graham',
  37077: 'Granville',
  37079: 'Greene',
  37081: 'Guilford',
  37083: 'Halifax',
  37085: 'Harnett',
  37087: 'Haywood',
  37089: 'Henderson',
  37091: 'Hertford',
  37093: 'Hoke',
  37095: 'Hyde',
  37097: 'Iredell',
  37099: 'Jackson',
  37101: 'Johnston',
  37103: 'Jones',
  37105: 'Lee',
  37107: 'Lenoir',
  37109: 'Lincoln',
  37111: 'McDowell',
  37113: 'Macon',
  37115: 'Madison',
  37117: 'Martin',
  37119: 'Mecklenburg',
  37121: 'Mitchell',
  37123: 'Montgomery',
  37125: 'Moore',
  37127: 'Nash',
  37129: 'New Hanover',
  37131: 'Northampton',
  37133: 'Onslow',
  37135: 'Orange',
  37137: 'Pamlico',
  37139: 'Pasquotank',
  37141: 'Pender',
  37143: 'Perquimans',
  37145: 'Person',
  37147: 'Pitt',
  37149: 'Polk',
  37151: 'Randolph',
  37153: 'Richmond',
  37155: 'Robeson',
  37157: 'Rockingham',
  37159: 'Rowan',
  37161: 'Rutherford',
  37163: 'Sampson',
  37165: 'Scotland',
  37167: 'Stanly',
  37169: 'Stokes',
  37171: 'Surry',
  37173: 'Swain',
  37175: 'Transylvania',
  37177: 'Tyrrell',
  37179: 'Union',
  37181: 'Vance',
  37183: 'Wake',
  37185: 'Warren',
  37187: 'Washington',
  37189: 'Watauga',
  37191: 'Wayne',
  37193: 'Wilkes',
  37195: 'Wilson',
  37197: 'Yadkin',
  37199: 'Yancey',
  38001: 'Adams',
  38003: 'Barnes',
  38005: 'Benson',
  38007: 'Billings',
  38009: 'Bottineau',
  38011: 'Bowman',
  38013: 'Burke',
  38015: 'Burleigh',
  38017: 'Cass',
  38019: 'Cavalier',
  38021: 'Dickey',
  38023: 'Divide',
  38025: 'Dunn',
  38027: 'Eddy',
  38029: 'Emmons',
  38031: 'Foster',
  38033: 'Golden Valley',
  38035: 'Grand Forks',
  38037: 'Grant',
  38039: 'Griggs',
  38041: 'Hettinger',
  38043: 'Kidder',
  38045: 'LaMoure',
  38047: 'Logan',
  38049: 'McHenry',
  38051: 'McIntosh',
  38053: 'McKenzie',
  38055: 'McLean',
  38057: 'Mercer',
  38059: 'Morton',
  38061: 'Mountrail',
  38063: 'Nelson',
  38065: 'Oliver',
  38067: 'Pembina',
  38069: 'Pierce',
  38071: 'Ramsey',
  38073: 'Ransom',
  38075: 'Renville',
  38077: 'Richland',
  38079: 'Rolette',
  38081: 'Sargent',
  38083: 'Sheridan',
  38085: 'Sioux',
  38087: 'Slope',
  38089: 'Stark',
  38091: 'Steele',
  38093: 'Stutsman',
  38095: 'Towner',
  38097: 'Traill',
  38099: 'Walsh',
  38101: 'Ward',
  38103: 'Wells',
  38105: 'Williams',
  39001: 'Adams',
  39003: 'Allen',
  39005: 'Ashland',
  39007: 'Ashtabula',
  39009: 'Athens',
  39011: 'Auglaize',
  39013: 'Belmont',
  39015: 'Brown',
  39017: 'Butler',
  39019: 'Carroll',
  39021: 'Champaign',
  39023: 'Clark',
  39025: 'Clermont',
  39027: 'Clinton',
  39029: 'Columbiana',
  39031: 'Coshocton',
  39033: 'Crawford',
  39035: 'Cuyahoga',
  39037: 'Darke',
  39039: 'Defiance',
  39041: 'Delaware',
  39043: 'Erie',
  39045: 'Fairfield',
  39047: 'Fayette',
  39049: 'Franklin',
  39051: 'Fulton',
  39053: 'Gallia',
  39055: 'Geauga',
  39057: 'Greene',
  39059: 'Guernsey',
  39061: 'Hamilton',
  39063: 'Hancock',
  39065: 'Hardin',
  39067: 'Harrison',
  39069: 'Henry',
  39071: 'Highland',
  39073: 'Hocking',
  39075: 'Holmes',
  39077: 'Huron',
  39079: 'Jackson',
  39081: 'Jefferson',
  39083: 'Knox',
  39085: 'Lake',
  39087: 'Lawrence',
  39089: 'Licking',
  39091: 'Logan',
  39093: 'Lorain',
  39095: 'Lucas',
  39097: 'Madison',
  39099: 'Mahoning',
  39101: 'Marion',
  39103: 'Medina',
  39105: 'Meigs',
  39107: 'Mercer',
  39109: 'Miami',
  39111: 'Monroe',
  39113: 'Montgomery',
  39115: 'Morgan',
  39117: 'Morrow',
  39119: 'Muskingum',
  39121: 'Noble',
  39123: 'Ottawa',
  39125: 'Paulding',
  39127: 'Perry',
  39129: 'Pickaway',
  39131: 'Pike',
  39133: 'Portage',
  39135: 'Preble',
  39137: 'Putnam',
  39139: 'Richland',
  39141: 'Ross',
  39143: 'Sandusky',
  39145: 'Scioto',
  39147: 'Seneca',
  39149: 'Shelby',
  39151: 'Stark',
  39153: 'Summit',
  39155: 'Trumbull',
  39157: 'Tuscarawas',
  39159: 'Union',
  39161: 'Van Wert',
  39163: 'Vinton',
  39165: 'Warren',
  39167: 'Washington',
  39169: 'Wayne',
  39171: 'Williams',
  39173: 'Wood',
  39175: 'Wyandot',
  40001: 'Adair',
  40003: 'Alfalfa',
  40005: 'Atoka',
  40007: 'Beaver',
  40009: 'Beckham',
  40011: 'Blaine',
  40013: 'Bryan',
  40015: 'Caddo',
  40017: 'Canadian',
  40019: 'Carter',
  40021: 'Cherokee',
  40023: 'Choctaw',
  40025: 'Cimarron',
  40027: 'Cleveland',
  40029: 'Coal',
  40031: 'Comanche',
  40033: 'Cotton',
  40035: 'Craig',
  40037: 'Creek',
  40039: 'Custer',
  40041: 'Delaware',
  40043: 'Dewey',
  40045: 'Ellis',
  40047: 'Garfield',
  40049: 'Garvin',
  40051: 'Grady',
  40053: 'Grant',
  40055: 'Greer',
  40057: 'Harmon',
  40059: 'Harper',
  40061: 'Haskell',
  40063: 'Hughes',
  40065: 'Jackson',
  40067: 'Jefferson',
  40069: 'Johnston',
  40071: 'Kay',
  40073: 'Kingfisher',
  40075: 'Kiowa',
  40077: 'Latimer',
  40079: 'Le Flore',
  40081: 'Lincoln',
  40083: 'Logan',
  40085: 'Love',
  40087: 'McClain',
  40089: 'McCurtain',
  40091: 'McIntosh',
  40093: 'Major',
  40095: 'Marshall',
  40097: 'Mayes',
  40099: 'Murray',
  40101: 'Muskogee',
  40103: 'Noble',
  40105: 'Nowata',
  40107: 'Okfuskee',
  40109: 'Oklahoma',
  40111: 'Okmulgee',
  40113: 'Osage',
  40115: 'Ottawa',
  40117: 'Pawnee',
  40119: 'Payne',
  40121: 'Pittsburg',
  40123: 'Pontotoc',
  40125: 'Pottawatomie',
  40127: 'Pushmataha',
  40129: 'Roger Mills',
  40131: 'Rogers',
  40133: 'Seminole',
  40135: 'Sequoyah',
  40137: 'Stephens',
  40139: 'Texas',
  40141: 'Tillman',
  40143: 'Tulsa',
  40145: 'Wagoner',
  40147: 'Washington',
  40149: 'Washita',
  40151: 'Woods',
  40153: 'Woodward',
  41001: 'Baker',
  41003: 'Benton',
  41005: 'Clackamas',
  41007: 'Clatsop',
  41009: 'Columbia',
  41011: 'Coos',
  41013: 'Crook',
  41015: 'Curry',
  41017: 'Deschutes',
  41019: 'Douglas',
  41021: 'Gilliam',
  41023: 'Grant',
  41025: 'Harney',
  41027: 'Hood River',
  41029: 'Jackson',
  41031: 'Jefferson',
  41033: 'Josephine',
  41035: 'Klamath',
  41037: 'Lake',
  41039: 'Lane',
  41041: 'Lincoln',
  41043: 'Linn',
  41045: 'Malheur',
  41047: 'Marion',
  41049: 'Morrow',
  41051: 'Multnomah',
  41053: 'Polk',
  41055: 'Sherman',
  41057: 'Tillamook',
  41059: 'Umatilla',
  41061: 'Union',
  41063: 'Wallowa',
  41065: 'Wasco',
  41067: 'Washington',
  41069: 'Wheeler',
  41071: 'Yamhill',
  42001: 'Adams',
  42003: 'Allegheny',
  42005: 'Armstrong',
  42007: 'Beaver',
  42009: 'Bedford',
  42011: 'Berks',
  42013: 'Blair',
  42015: 'Bradford',
  42017: 'Bucks',
  42019: 'Butler',
  42021: 'Cambria',
  42023: 'Cameron',
  42025: 'Carbon',
  42027: 'Centre',
  42029: 'Chester',
  42031: 'Clarion',
  42033: 'Clearfield',
  42035: 'Clinton',
  42037: 'Columbia',
  42039: 'Crawford',
  42041: 'Cumberland',
  42043: 'Dauphin',
  42045: 'Delaware',
  42047: 'Elk',
  42049: 'Erie',
  42051: 'Fayette',
  42053: 'Forest',
  42055: 'Franklin',
  42057: 'Fulton',
  42059: 'Greene',
  42061: 'Huntingdon',
  42063: 'Indiana',
  42065: 'Jefferson',
  42067: 'Juniata',
  42069: 'Lackawanna',
  42071: 'Lancaster',
  42073: 'Lawrence',
  42075: 'Lebanon',
  42077: 'Lehigh',
  42079: 'Luzerne',
  42081: 'Lycoming',
  42083: 'McKean',
  42085: 'Mercer',
  42087: 'Mifflin',
  42089: 'Monroe',
  42091: 'Montgomery',
  42093: 'Montour',
  42095: 'Northampton',
  42097: 'Northumberland',
  42099: 'Perry',
  42101: 'Philadelphia',
  42103: 'Pike',
  42105: 'Potter',
  42107: 'Schuylkill',
  42109: 'Snyder',
  42111: 'Somerset',
  42113: 'Sullivan',
  42115: 'Susquehanna',
  42117: 'Tioga',
  42119: 'Union',
  42121: 'Venango',
  42123: 'Warren',
  42125: 'Washington',
  42127: 'Wayne',
  42129: 'Westmoreland',
  42131: 'Wyoming',
  42133: 'York',
  44001: 'Bristol',
  44003: 'Kent',
  44005: 'Newport',
  44007: 'Providence',
  44009: 'Washington',
  45001: 'Abbeville',
  45003: 'Aiken',
  45005: 'Allendale',
  45007: 'Anderson',
  45009: 'Bamberg',
  45011: 'Barnwell',
  45013: 'Beaufort',
  45015: 'Berkeley',
  45017: 'Calhoun',
  45019: 'Charleston',
  45021: 'Cherokee',
  45023: 'Chester',
  45025: 'Chesterfield',
  45027: 'Clarendon',
  45029: 'Colleton',
  45031: 'Darlington',
  45033: 'Dillon',
  45035: 'Dorchester',
  45037: 'Edgefield',
  45039: 'Fairfield',
  45041: 'Florence',
  45043: 'Georgetown',
  45045: 'Greenville',
  45047: 'Greenwood',
  45049: 'Hampton',
  45051: 'Horry',
  45053: 'Jasper',
  45055: 'Kershaw',
  45057: 'Lancaster',
  45059: 'Laurens',
  45061: 'Lee',
  45063: 'Lexington',
  45065: 'McCormick',
  45067: 'Marion',
  45069: 'Marlboro',
  45071: 'Newberry',
  45073: 'Oconee',
  45075: 'Orangeburg',
  45077: 'Pickens',
  45079: 'Richland',
  45081: 'Saluda',
  45083: 'Spartanburg',
  45085: 'Sumter',
  45087: 'Union',
  45089: 'Williamsburg',
  45091: 'York',
  46003: 'Aurora',
  46005: 'Beadle',
  46007: 'Bennett',
  46009: 'Bon Homme',
  46011: 'Brookings',
  46013: 'Brown',
  46015: 'Brule',
  46017: 'Buffalo',
  46019: 'Butte',
  46021: 'Campbell',
  46023: 'Charles Mix',
  46025: 'Clark',
  46027: 'Clay',
  46029: 'Codington',
  46031: 'Corson',
  46033: 'Custer',
  46035: 'Davison',
  46037: 'Day',
  46039: 'Deuel',
  46041: 'Dewey',
  46043: 'Douglas',
  46045: 'Edmunds',
  46047: 'Fall River',
  46049: 'Faulk',
  46051: 'Grant',
  46053: 'Gregory',
  46055: 'Haakon',
  46057: 'Hamlin',
  46059: 'Hand',
  46061: 'Hanson',
  46063: 'Harding',
  46065: 'Hughes',
  46067: 'Hutchinson',
  46069: 'Hyde',
  46071: 'Jackson',
  46073: 'Jerauld',
  46075: 'Jones',
  46077: 'Kingsbury',
  46079: 'Lake',
  46081: 'Lawrence',
  46083: 'Lincoln',
  46085: 'Lyman',
  46087: 'McCook',
  46089: 'McPherson',
  46091: 'Marshall',
  46093: 'Meade',
  46095: 'Mellette',
  46097: 'Miner',
  46099: 'Minnehaha',
  46101: 'Moody',
  46102: 'Oglala Lakota',
  46103: 'Pennington',
  46105: 'Perkins',
  46107: 'Potter',
  46109: 'Roberts',
  46111: 'Sanborn',
  46115: 'Spink',
  46117: 'Stanley',
  46119: 'Sully',
  46121: 'Todd',
  46123: 'Tripp',
  46125: 'Turner',
  46127: 'Union',
  46129: 'Walworth',
  46135: 'Yankton',
  46137: 'Ziebach',
  47001: 'Anderson',
  47003: 'Bedford',
  47005: 'Benton',
  47007: 'Bledsoe',
  47009: 'Blount',
  47011: 'Bradley',
  47013: 'Campbell',
  47015: 'Cannon',
  47017: 'Carroll',
  47019: 'Carter',
  47021: 'Cheatham',
  47023: 'Chester',
  47025: 'Claiborne',
  47027: 'Clay',
  47029: 'Cocke',
  47031: 'Coffee',
  47033: 'Crockett',
  47035: 'Cumberland',
  47037: 'Davidson',
  47039: 'Decatur',
  47041: 'DeKalb',
  47043: 'Dickson',
  47045: 'Dyer',
  47047: 'Fayette',
  47049: 'Fentress',
  47051: 'Franklin',
  47053: 'Gibson',
  47055: 'Giles',
  47057: 'Grainger',
  47059: 'Greene',
  47061: 'Grundy',
  47063: 'Hamblen',
  47065: 'Hamilton',
  47067: 'Hancock',
  47069: 'Hardeman',
  47071: 'Hardin',
  47073: 'Hawkins',
  47075: 'Haywood',
  47077: 'Henderson',
  47079: 'Henry',
  47081: 'Hickman',
  47083: 'Houston',
  47085: 'Humphreys',
  47087: 'Jackson',
  47089: 'Jefferson',
  47091: 'Johnson',
  47093: 'Knox',
  47095: 'Lake',
  47097: 'Lauderdale',
  47099: 'Lawrence',
  47101: 'Lewis',
  47103: 'Lincoln',
  47105: 'Loudon',
  47107: 'McMinn',
  47109: 'McNairy',
  47111: 'Macon',
  47113: 'Madison',
  47115: 'Marion',
  47117: 'Marshall',
  47119: 'Maury',
  47121: 'Meigs',
  47123: 'Monroe',
  47125: 'Montgomery',
  47127: 'Moore',
  47129: 'Morgan',
  47131: 'Obion',
  47133: 'Overton',
  47135: 'Perry',
  47137: 'Pickett',
  47139: 'Polk',
  47141: 'Putnam',
  47143: 'Rhea',
  47145: 'Roane',
  47147: 'Robertson',
  47149: 'Rutherford',
  47151: 'Scott',
  47153: 'Sequatchie',
  47155: 'Sevier',
  47157: 'Shelby',
  47159: 'Smith',
  47161: 'Stewart',
  47163: 'Sullivan',
  47165: 'Sumner',
  47167: 'Tipton',
  47169: 'Trousdale',
  47171: 'Unicoi',
  47173: 'Union',
  47175: 'Van Buren',
  47177: 'Warren',
  47179: 'Washington',
  47181: 'Wayne',
  47183: 'Weakley',
  47185: 'White',
  47187: 'Williamson',
  47189: 'Wilson',
  48001: 'Anderson',
  48003: 'Andrews',
  48005: 'Angelina',
  48007: 'Aransas',
  48009: 'Archer',
  48011: 'Armstrong',
  48013: 'Atascosa',
  48015: 'Austin',
  48017: 'Bailey',
  48019: 'Bandera',
  48021: 'Bastrop',
  48023: 'Baylor',
  48025: 'Bee',
  48027: 'Bell',
  48029: 'Bexar',
  48031: 'Blanco',
  48033: 'Borden',
  48035: 'Bosque',
  48037: 'Bowie',
  48039: 'Brazoria',
  48041: 'Brazos',
  48043: 'Brewster',
  48045: 'Briscoe',
  48047: 'Brooks',
  48049: 'Brown',
  48051: 'Burleson',
  48053: 'Burnet',
  48055: 'Caldwell',
  48057: 'Calhoun',
  48059: 'Callahan',
  48061: 'Cameron',
  48063: 'Camp',
  48065: 'Carson',
  48067: 'Cass',
  48069: 'Castro',
  48071: 'Chambers',
  48073: 'Cherokee',
  48075: 'Childress',
  48077: 'Clay',
  48079: 'Cochran',
  48081: 'Coke',
  48083: 'Coleman',
  48085: 'Collin',
  48087: 'Collingsworth',
  48089: 'Colorado',
  48091: 'Comal',
  48093: 'Comanche',
  48095: 'Concho',
  48097: 'Cooke',
  48099: 'Coryell',
  48101: 'Cottle',
  48103: 'Crane',
  48105: 'Crockett',
  48107: 'Crosby',
  48109: 'Culberson',
  48111: 'Dallam',
  48113: 'Dallas',
  48115: 'Dawson',
  48117: 'Deaf Smith',
  48119: 'Delta',
  48121: 'Denton',
  48123: 'DeWitt',
  48125: 'Dickens',
  48127: 'Dimmit',
  48129: 'Donley',
  48131: 'Duval',
  48133: 'Eastland',
  48135: 'Ector',
  48137: 'Edwards',
  48139: 'Ellis',
  48141: 'El Paso',
  48143: 'Erath',
  48145: 'Falls',
  48147: 'Fannin',
  48149: 'Fayette',
  48151: 'Fisher',
  48153: 'Floyd',
  48155: 'Foard',
  48157: 'Fort Bend',
  48159: 'Franklin',
  48161: 'Freestone',
  48163: 'Frio',
  48165: 'Gaines',
  48167: 'Galveston',
  48169: 'Garza',
  48171: 'Gillespie',
  48173: 'Glasscock',
  48175: 'Goliad',
  48177: 'Gonzales',
  48179: 'Gray',
  48181: 'Grayson',
  48183: 'Gregg',
  48185: 'Grimes',
  48187: 'Guadalupe',
  48189: 'Hale',
  48191: 'Hall',
  48193: 'Hamilton',
  48195: 'Hansford',
  48197: 'Hardeman',
  48199: 'Hardin',
  48201: 'Harris',
  48203: 'Harrison',
  48205: 'Hartley',
  48207: 'Haskell',
  48209: 'Hays',
  48211: 'Hemphill',
  48213: 'Henderson',
  48215: 'Hidalgo',
  48217: 'Hill',
  48219: 'Hockley',
  48221: 'Hood',
  48223: 'Hopkins',
  48225: 'Houston',
  48227: 'Howard',
  48229: 'Hudspeth',
  48231: 'Hunt',
  48233: 'Hutchinson',
  48235: 'Irion',
  48237: 'Jack',
  48239: 'Jackson',
  48241: 'Jasper',
  48243: 'Jeff Davis',
  48245: 'Jefferson',
  48247: 'Jim Hogg',
  48249: 'Jim Wells',
  48251: 'Johnson',
  48253: 'Jones',
  48255: 'Karnes',
  48257: 'Kaufman',
  48259: 'Kendall',
  48261: 'Kenedy',
  48263: 'Kent',
  48265: 'Kerr',
  48267: 'Kimble',
  48269: 'King',
  48271: 'Kinney',
  48273: 'Kleberg',
  48275: 'Knox',
  48277: 'Lamar',
  48279: 'Lamb',
  48281: 'Lampasas',
  48283: 'La Salle',
  48285: 'Lavaca',
  48287: 'Lee',
  48289: 'Leon',
  48291: 'Liberty',
  48293: 'Limestone',
  48295: 'Lipscomb',
  48297: 'Live Oak',
  48299: 'Llano',
  48301: 'Loving',
  48303: 'Lubbock',
  48305: 'Lynn',
  48307: 'McCulloch',
  48309: 'McLennan',
  48311: 'McMullen',
  48313: 'Madison',
  48315: 'Marion',
  48317: 'Martin',
  48319: 'Mason',
  48321: 'Matagorda',
  48323: 'Maverick',
  48325: 'Medina',
  48327: 'Menard',
  48329: 'Midland',
  48331: 'Milam',
  48333: 'Mills',
  48335: 'Mitchell',
  48337: 'Montague',
  48339: 'Montgomery',
  48341: 'Moore',
  48343: 'Morris',
  48345: 'Motley',
  48347: 'Nacogdoches',
  48349: 'Navarro',
  48351: 'Newton',
  48353: 'Nolan',
  48355: 'Nueces',
  48357: 'Ochiltree',
  48359: 'Oldham',
  48361: 'Orange',
  48363: 'Palo Pinto',
  48365: 'Panola',
  48367: 'Parker',
  48369: 'Parmer',
  48371: 'Pecos',
  48373: 'Polk',
  48375: 'Potter',
  48377: 'Presidio',
  48379: 'Rains',
  48381: 'Randall',
  48383: 'Reagan',
  48385: 'Real',
  48387: 'Red River',
  48389: 'Reeves',
  48391: 'Refugio',
  48393: 'Roberts',
  48395: 'Robertson',
  48397: 'Rockwall',
  48399: 'Runnels',
  48401: 'Rusk',
  48403: 'Sabine',
  48405: 'San Augustine',
  48407: 'San Jacinto',
  48409: 'San Patricio',
  48411: 'San Saba',
  48413: 'Schleicher',
  48415: 'Scurry',
  48417: 'Shackelford',
  48419: 'Shelby',
  48421: 'Sherman',
  48423: 'Smith',
  48425: 'Somervell',
  48427: 'Starr',
  48429: 'Stephens',
  48431: 'Sterling',
  48433: 'Stonewall',
  48435: 'Sutton',
  48437: 'Swisher',
  48439: 'Tarrant',
  48441: 'Taylor',
  48443: 'Terrell',
  48445: 'Terry',
  48447: 'Throckmorton',
  48449: 'Titus',
  48451: 'Tom Green',
  48453: 'Travis',
  48455: 'Trinity',
  48457: 'Tyler',
  48459: 'Upshur',
  48461: 'Upton',
  48463: 'Uvalde',
  48465: 'Val Verde',
  48467: 'Van Zandt',
  48469: 'Victoria',
  48471: 'Walker',
  48473: 'Waller',
  48475: 'Ward',
  48477: 'Washington',
  48479: 'Webb',
  48481: 'Wharton',
  48483: 'Wheeler',
  48485: 'Wichita',
  48487: 'Wilbarger',
  48489: 'Willacy',
  48491: 'Williamson',
  48493: 'Wilson',
  48495: 'Winkler',
  48497: 'Wise',
  48499: 'Wood',
  48501: 'Yoakum',
  48503: 'Young',
  48505: 'Zapata',
  48507: 'Zavala',
  49001: 'Beaver',
  49003: 'Box Elder',
  49005: 'Cache',
  49007: 'Carbon',
  49009: 'Daggett',
  49011: 'Davis',
  49013: 'Duchesne',
  49015: 'Emery',
  49017: 'Garfield',
  49019: 'Grand',
  49021: 'Iron',
  49023: 'Juab',
  49025: 'Kane',
  49027: 'Millard',
  49029: 'Morgan',
  49031: 'Piute',
  49033: 'Rich',
  49035: 'Salt Lake',
  49037: 'San Juan',
  49039: 'Sanpete',
  49041: 'Sevier',
  49043: 'Summit',
  49045: 'Tooele',
  49047: 'Uintah',
  49049: 'Utah',
  49051: 'Wasatch',
  49053: 'Washington',
  49055: 'Wayne',
  49057: 'Weber',
  50001: 'Addison',
  50003: 'Bennington',
  50005: 'Caledonia',
  50007: 'Chittenden',
  50009: 'Essex',
  50011: 'Franklin',
  50013: 'Grand Isle',
  50015: 'Lamoille',
  50017: 'Orange',
  50019: 'Orleans',
  50021: 'Rutland',
  50023: 'Washington',
  50025: 'Windham',
  50027: 'Windsor',
  51001: 'Accomack',
  51003: 'Albemarle',
  51005: 'Alleghany',
  51007: 'Amelia',
  51009: 'Amherst',
  51011: 'Appomattox',
  51013: 'Arlington',
  51015: 'Augusta',
  51017: 'Bath',
  51019: 'Bedford',
  51021: 'Bland',
  51023: 'Botetourt',
  51025: 'Brunswick',
  51027: 'Buchanan',
  51029: 'Buckingham',
  51031: 'Campbell',
  51033: 'Caroline',
  51035: 'Carroll',
  51036: 'Charles City',
  51037: 'Charlotte',
  51041: 'Chesterfield',
  51043: 'Clarke',
  51045: 'Craig',
  51047: 'Culpeper',
  51049: 'Cumberland',
  51051: 'Dickenson',
  51053: 'Dinwiddie',
  51057: 'Essex',
  51059: 'Fairfax',
  51061: 'Fauquier',
  51063: 'Floyd',
  51065: 'Fluvanna',
  51067: 'Franklin',
  51069: 'Frederick',
  51071: 'Giles',
  51073: 'Gloucester',
  51075: 'Goochland',
  51077: 'Grayson',
  51079: 'Greene',
  51081: 'Greensville',
  51083: 'Halifax',
  51085: 'Hanover',
  51087: 'Henrico',
  51089: 'Henry',
  51091: 'Highland',
  51093: 'Isle of Wight',
  51095: 'James City',
  51097: 'King and Queen',
  51099: 'King George',
  51101: 'King William',
  51103: 'Lancaster',
  51105: 'Lee',
  51107: 'Loudoun',
  51109: 'Louisa',
  51111: 'Lunenburg',
  51113: 'Madison',
  51115: 'Mathews',
  51117: 'Mecklenburg',
  51119: 'Middlesex',
  51121: 'Montgomery',
  51125: 'Nelson',
  51127: 'New Kent',
  51131: 'Northampton',
  51133: 'Northumberland',
  51135: 'Nottoway',
  51137: 'Orange',
  51139: 'Page',
  51141: 'Patrick',
  51143: 'Pittsylvania',
  51145: 'Powhatan',
  51147: 'Prince Edward',
  51149: 'Prince George',
  51153: 'Prince William',
  51155: 'Pulaski',
  51157: 'Rappahannock',
  51159: 'Richmond',
  51161: 'Roanoke',
  51163: 'Rockbridge',
  51165: 'Rockingham',
  51167: 'Russell',
  51169: 'Scott',
  51171: 'Shenandoah',
  51173: 'Smyth',
  51175: 'Southampton',
  51177: 'Spotsylvania',
  51179: 'Stafford',
  51181: 'Surry',
  51183: 'Sussex',
  51185: 'Tazewell',
  51187: 'Warren',
  51191: 'Washington',
  51193: 'Westmoreland',
  51195: 'Wise',
  51197: 'Wythe',
  51199: 'York',
  51510: 'Alexandria',
  51520: 'Bristol',
  51530: 'Buena Vista',
  51540: 'Charlottesville',
  51550: 'Chesapeake',
  51570: 'Colonial Heights',
  51580: 'Covington',
  51590: 'Danville',
  51595: 'Emporia',
  51600: 'Fairfax',
  51610: 'Falls Church',
  51620: 'Franklin',
  51630: 'Fredericksburg',
  51640: 'Galax',
  51650: 'Hampton',
  51660: 'Harrisonburg',
  51670: 'Hopewell',
  51678: 'Lexington',
  51680: 'Lynchburg',
  51683: 'Manassas',
  51685: 'Manassas Park',
  51690: 'Martinsville',
  51700: 'Newport News',
  51710: 'Norfolk',
  51720: 'Norton',
  51730: 'Petersburg',
  51735: 'Poquoson',
  51740: 'Portsmouth',
  51750: 'Radford',
  51760: 'Richmond',
  51770: 'Roanoke',
  51775: 'Salem',
  51790: 'Staunton',
  51800: 'Suffolk',
  51810: 'Virginia Beach',
  51820: 'Waynesboro',
  51830: 'Williamsburg',
  51840: 'Winchester',
  53001: 'Adams',
  53003: 'Asotin',
  53005: 'Benton',
  53007: 'Chelan',
  53009: 'Clallam',
  53011: 'Clark',
  53013: 'Columbia',
  53015: 'Cowlitz',
  53017: 'Douglas',
  53019: 'Ferry',
  53021: 'Franklin',
  53023: 'Garfield',
  53025: 'Grant',
  53027: 'Grays Harbor',
  53029: 'Island',
  53031: 'Jefferson',
  53033: 'King',
  53035: 'Kitsap',
  53037: 'Kittitas',
  53039: 'Klickitat',
  53041: 'Lewis',
  53043: 'Lincoln',
  53045: 'Mason',
  53047: 'Okanogan',
  53049: 'Pacific',
  53051: 'Pend Oreille',
  53053: 'Pierce',
  53055: 'San Juan',
  53057: 'Skagit',
  53059: 'Skamania',
  53061: 'Snohomish',
  53063: 'Spokane',
  53065: 'Stevens',
  53067: 'Thurston',
  53069: 'Wahkiakum',
  53071: 'Walla Walla',
  53073: 'Whatcom',
  53075: 'Whitman',
  53077: 'Yakima',
  54001: 'Barbour',
  54003: 'Berkeley',
  54005: 'Boone',
  54007: 'Braxton',
  54009: 'Brooke',
  54011: 'Cabell',
  54013: 'Calhoun',
  54015: 'Clay',
  54017: 'Doddridge',
  54019: 'Fayette',
  54021: 'Gilmer',
  54023: 'Grant',
  54025: 'Greenbrier',
  54027: 'Hampshire',
  54029: 'Hancock',
  54031: 'Hardy',
  54033: 'Harrison',
  54035: 'Jackson',
  54037: 'Jefferson',
  54039: 'Kanawha',
  54041: 'Lewis',
  54043: 'Lincoln',
  54045: 'Logan',
  54047: 'McDowell',
  54049: 'Marion',
  54051: 'Marshall',
  54053: 'Mason',
  54055: 'Mercer',
  54057: 'Mineral',
  54059: 'Mingo',
  54061: 'Monongalia',
  54063: 'Monroe',
  54065: 'Morgan',
  54067: 'Nicholas',
  54069: 'Ohio',
  54071: 'Pendleton',
  54073: 'Pleasants',
  54075: 'Pocahontas',
  54077: 'Preston',
  54079: 'Putnam',
  54081: 'Raleigh',
  54083: 'Randolph',
  54085: 'Ritchie',
  54087: 'Roane',
  54089: 'Summers',
  54091: 'Taylor',
  54093: 'Tucker',
  54095: 'Tyler',
  54097: 'Upshur',
  54099: 'Wayne',
  54101: 'Webster',
  54103: 'Wetzel',
  54105: 'Wirt',
  54107: 'Wood',
  54109: 'Wyoming',
  55001: 'Adams',
  55003: 'Ashland',
  55005: 'Barron',
  55007: 'Bayfield',
  55009: 'Brown',
  55011: 'Buffalo',
  55013: 'Burnett',
  55015: 'Calumet',
  55017: 'Chippewa',
  55019: 'Clark',
  55021: 'Columbia',
  55023: 'Crawford',
  55025: 'Dane',
  55027: 'Dodge',
  55029: 'Door',
  55031: 'Douglas',
  55033: 'Dunn',
  55035: 'Eau Claire',
  55037: 'Florence',
  55039: 'Fond du Lac',
  55041: 'Forest',
  55043: 'Grant',
  55045: 'Green',
  55047: 'Green Lake',
  55049: 'Iowa',
  55051: 'Iron',
  55053: 'Jackson',
  55055: 'Jefferson',
  55057: 'Juneau',
  55059: 'Kenosha',
  55061: 'Kewaunee',
  55063: 'La Crosse',
  55065: 'Lafayette',
  55067: 'Langlade',
  55069: 'Lincoln',
  55071: 'Manitowoc',
  55073: 'Marathon',
  55075: 'Marinette',
  55077: 'Marquette',
  55078: 'Menominee',
  55079: 'Milwaukee',
  55081: 'Monroe',
  55083: 'Oconto',
  55085: 'Oneida',
  55087: 'Outagamie',
  55089: 'Ozaukee',
  55091: 'Pepin',
  55093: 'Pierce',
  55095: 'Polk',
  55097: 'Portage',
  55099: 'Price',
  55101: 'Racine',
  55103: 'Richland',
  55105: 'Rock',
  55107: 'Rusk',
  55109: 'St. Croix',
  55111: 'Sauk',
  55113: 'Sawyer',
  55115: 'Shawano',
  55117: 'Sheboygan',
  55119: 'Taylor',
  55121: 'Trempealeau',
  55123: 'Vernon',
  55125: 'Vilas',
  55127: 'Walworth',
  55129: 'Washburn',
  55131: 'Washington',
  55133: 'Waukesha',
  55135: 'Waupaca',
  55137: 'Waushara',
  55139: 'Winnebago',
  55141: 'Wood',
  56001: 'Albany',
  56003: 'Big Horn',
  56005: 'Campbell',
  56007: 'Carbon',
  56009: 'Converse',
  56011: 'Crook',
  56013: 'Fremont',
  56015: 'Goshen',
  56017: 'Hot Springs',
  56019: 'Johnson',
  56021: 'Laramie',
  56023: 'Lincoln',
  56025: 'Natrona',
  56027: 'Niobrara',
  56029: 'Park',
  56031: 'Platte',
  56033: 'Sheridan',
  56035: 'Sublette',
  56037: 'Sweetwater',
  56039: 'Teton',
  56041: 'Uinta',
  56043: 'Washakie',
  56045: 'Weston',
  66010: 'Guam',
  69085: 'Northern Islands',
  69100: 'Rota',
  69110: 'Saipan',
  69120: 'Tinian',
  72001: 'Adjuntas',
  72003: 'Aguada',
  72005: 'Aguadilla',
  72007: 'Aguas Buenas',
  72009: 'Aibonito',
  72011: 'Añasco',
  72013: 'Arecibo',
  72015: 'Arroyo',
  72017: 'Barceloneta',
  72019: 'Barranquitas',
  72021: 'Bayamón',
  72023: 'Cabo Rojo',
  72025: 'Caguas',
  72027: 'Camuy',
  72029: 'Canóvanas',
  72031: 'Carolina',
  72033: 'Cataño',
  72035: 'Cayey',
  72037: 'Ceiba',
  72039: 'Ciales',
  72041: 'Cidra',
  72043: 'Coamo',
  72045: 'Comerío',
  72047: 'Corozal',
  72049: 'Culebra',
  72051: 'Dorado',
  72053: 'Fajardo',
  72054: 'Florida',
  72055: 'Guánica',
  72057: 'Guayama',
  72059: 'Guayanilla',
  72061: 'Guaynabo',
  72063: 'Gurabo',
  72065: 'Hatillo',
  72067: 'Hormigueros',
  72069: 'Humacao',
  72071: 'Isabela',
  72073: 'Jayuya',
  72075: 'Juana Díaz',
  72077: 'Juncos',
  72079: 'Lajas',
  72081: 'Lares',
  72083: 'Las Marías',
  72085: 'Las Piedras',
  72087: 'Loíza',
  72089: 'Luquillo',
  72091: 'Manatí',
  72093: 'Maricao',
  72095: 'Maunabo',
  72097: 'Mayagüez',
  72099: 'Moca',
  72101: 'Morovis',
  72103: 'Naguabo',
  72105: 'Naranjito',
  72107: 'Orocovis',
  72109: 'Patillas',
  72111: 'Peñuelas',
  72113: 'Ponce',
  72115: 'Quebradillas',
  72117: 'Rincón',
  72119: 'Río Grande',
  72121: 'Sabana Grande',
  72123: 'Salinas',
  72125: 'San Germán',
  72127: 'San Juan',
  72129: 'San Lorenzo',
  72131: 'San Sebastián',
  72133: 'Santa Isabel',
  72135: 'Toa Alta',
  72137: 'Toa Baja',
  72139: 'Trujillo Alto',
  72141: 'Utuado',
  72143: 'Vega Alta',
  72145: 'Vega Baja',
  72147: 'Vieques',
  72149: 'Villalba',
  72151: 'Yabucoa',
  72153: 'Yauco',
  78010: 'St. Croix',
  78020: 'St. John',
  78030: 'St. Thomas',
  /* cSpell:enable */
}

export const INDEPENDENT_CITIES = [
  // Virginia
  '51510',
  '51520',
  '51530',
  '51540',
  '51550',
  '51570',
  '51580',
  '51590',
  '51595',
  '51600',
  '51610',
  '51620',
  '51630',
  '51640',
  '51650',
  '51660',
  '51670',
  '51678',
  '51680',
  '51683',
  '51685',
  '51690',
  '51700',
  '51710',
  '51720',
  '51730',
  '51735',
  '51740',
  '51750',
  '51760',
  '51770',
  '51775',
  '51790',
  '51800',
  '51810',
  '51820',
  '51830',
  '51840',
  // Baltimore, MD
  '24510',
  // St. Louis, MO
  '29510',
  // Carson City, NV
  '32510',
]

export const FIPS_MAP = { ...STATE_FIPS_MAP, ...COUNTY_FIPS_MAP }
